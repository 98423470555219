// Third party libraries
import { Route } from 'react-router-dom';

// Components
import GradientNav from '../../components/navbar/gradient';
import SubMenu from '../../components/navbar/submenu';
import UserAccount from './components/userAccount';
import UserObjectives from './components/userObjectives';
import UserSecurity from './components/userSecurity';
import UserSuscription from './components/userSuscription';
import UserPromotion from './components/userPromotion';
import UserPlans from './components/userPlans';
import WalletLinkRef from './components/wallet/WalletLinkRef';

const Profile = props => {

    return <>
        <GradientNav active='profile' { ...props } />
        <Route path='/profile/account' exact component={ UserAccount } />
        <Route path='/profile/objectives' exact component={ UserObjectives } />
        <Route path='/profile/security' exact component={ UserSecurity } />
        <Route path='/profile/suscription' exact component={ UserSuscription } />
        <Route path='/profile/promotion' exact component={ UserPromotion } />
        <Route path='/profile/plans' exact component={ UserPlans } />
        <Route path='/profile/wallet/reflink' exact component={ WalletLinkRef } />
        <SubMenu active='profile' { ...props } />
    </>;

}

export default Profile;
