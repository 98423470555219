// Third party libraries
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import Moment from 'moment';
import { Table, Icon } from 'semantic-ui-react';

// General components
import Footer from '../../../components/footer';
import MenuProfile from '../../../components/menu/profile';
import SideBarProFileHoc from '../../../components/sidebar/profile';
import TopBar from '../../../components/navbar/topbar';

// Styled components
import OButton from '../../../styled/components/button';

// Models
import PaymentModel from '../../../../data/models/payment/payment';
import SecurityModel from '../../../../data/models/security/security';

// Redux
import { getRole } from '../../../../redux-store/role';
import { openLoader, closeLoader } from '../../../../redux-store/loader';
import { openGenericModal, closeGenericModal } from '../../../../redux-store/genericModal';

// Utils
import { hasOneYearPaymentPromotion } from '../../../../utils/checkPromotions';

// Locales
import I18n from '../../../../i18n';

// Styles
import '../profile_new.scss';

// Assets
import YGBIcon from '../../../../ui/assets/img/ygb_icon.png';

const RowItem = ({ billings, onDownloadInvoice }) => {

    const setCurrencyAndAmount = (amount, currency) => {

        switch(currency) {

            case 'USD':

                return `$${amount}`;

            case 'EUR':

                return `${amount}€`;

            case 'YGB':

                return `${amount}YGB`;

            default:

                return '';

        }

    }

    const setPaymentMethod = (platform, last4 = null, brand) => {

        if (platform === 'stripe') {

            switch (brand) {

                case 'amex':
                case 'mastercard':
                case 'visa':

                    return <React.Fragment><span className={ `f-icon-cc-${ brand } icon-wallet` }></span>&nbsp;<span>•••• •••• •••• { last4 }</span></React.Fragment>

                default:

                    return <React.Fragment><span className="f-icon-credit-card icon-wallet"></span>&nbsp;<span>•••• •••• •••• { last4 }</span></React.Fragment>

            }

        } else if (platform === 'yogabot') {

            const styles = { marginLeft:'8px', display:'inline-block', position: 'relative', 'top': '-4px' };
            return <React.Fragment><img src={ YGBIcon } alt="ygb token" style={{ width: '20px' }} /><span style={ styles }>YGB</span></React.Fragment>

        } else if (platform === 'iOS') {

            return <Icon disabled name='apple' />

        } else if (platform === 'Android') {

            return <Icon disabled name='google play' />

        }

        return <span className="icon-wallet"></span>

    }

    const setDateBlock = (startDate, endDate) => {

        if (!endDate) {

            return  Moment(startDate).format('DD/MM/YYYY')

        }

        return  Moment(startDate).format('DD/MM/YYYY') + ' - ' + Moment(endDate).format('DD/MM/YYYY')

    }

    return billings.map(billing => (
        <Table.Row key={ billing._id }>
            <Table.Cell>{ billing.receiptNumber }</Table.Cell>
            <Table.Cell>{ Moment(billing.createdAt).format('DD/MM/YYYY') }</Table.Cell>
            <Table.Cell>{ billing.type === 'investment' ? `Compra de ${ billing.tokens }YGB` : billing.description }</Table.Cell>
            <Table.Cell>{ setDateBlock(billing.startDate, billing.endDate)}</Table.Cell>
            <Table.Cell>{ billing.currency === 'School Product' ?  I18n.t('profile.suscription.exchangedCredits'): setPaymentMethod(billing.platform, _.get(billing, 'last4', null), _.get(billing, 'brand', '')) }</Table.Cell>
            <Table.Cell><span className="cbrand poppins-bold">{ setCurrencyAndAmount(billing.amount, billing.currency) }</span></Table.Cell>
            <Table.Cell style={{textAlign:'center'}}><span onClick={ () => onDownloadInvoice(billing._id, billing.type) }><i className="f-icon-descarga"></i></span></Table.Cell>
        </Table.Row>
    ));

}

const InfoBlock = ({ role, premiumExpirationDate, isPremium }) => {

    const renderPremiumBlock = endDate => {

        const isValidDate = endDate !== 'NOT_EXPIRE';
        const now = Moment();
        const countDown = isValidDate ? Math.ceil(Moment(endDate).diff(now, 'days')) : 'De por vida';
        const countDownFormat = isValidDate && Moment(endDate).format('DD/MM/YYYY');

        return (
            <>
                <div className="info-item">
                    <p className="info-title">{I18n.t('checkout.premiumSuscription')}</p>
                    {isValidDate ? <p className="info-subtitle">{I18n.t('checkout.countDownDate', { countDown: countDown})}</p> : <p className="info-subtitle">{countDown}</p>}
                </div>
                { isValidDate && <div className="info-item">
                    <p className="info-title">{I18n.t('checkout.suscriptionType')}</p>
                    <p className="info-subtitle">{I18n.t('checkout.anual')}</p>
                </div> }
                {isValidDate && <div className="info-item">
                    <p className="info-title">{I18n.t('checkout.cutDate')}</p>
                    <p className="info-subtitle">{countDownFormat}</p>
                </div>}
            </>
        );

    }

    const renderFreeBlock = () => {

        return (
            <div className="info-item high-color">
                <p className="info-title" style={{textAlign:'center'}}>{I18n.t('checkout.basicSuscription')}</p>
                <p className="info-subtitle" style={{textAlign:'center'}}>{I18n.t('checkout.free')}</p>
            </div>
        );

    }

    return isPremium ? renderPremiumBlock(premiumExpirationDate) : renderFreeBlock();

}

class UserSuscription extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            billings : [],
            isPremiumByPro: false,
            proInfo: null
        };

    }

    async componentDidMount() {

        try {

            this.props.openLoader();
            const response = await PaymentModel.getUserPayments();
            const responseUserTransactions = await PaymentModel.getTransactionsMovements();
            const userInvestments = _.filter(responseUserTransactions.data, transaction => transaction.type === 'investment');

            if (response.data) {

                if (userInvestments) {

                    response.data = [...response.data, ...userInvestments];

                }

                // Para saber si tiene una suscripción PREMIUM que no pagó, por lo que se la regaló su profesor
                const paySub = response.data.some(bill => bill?.orderId?.startsWith("sub_"));

                const { role: { hasActiveSubscription } } = this.props;

                const isPremiumByPro = !paySub && hasActiveSubscription;

                const prosInfo = await SecurityModel.getProFullInfo();

                const proInfo = prosInfo.find(pro => pro.paySubscription === true);

                this.setState({ billings: response.data.sort((bill1, bill2) => {

                    const bill1CreatedAt = Moment(bill1.createdAt);
                    const bill2CreatedAt = Moment(bill2.createdAt);

                    if (bill1CreatedAt < bill2CreatedAt) return 1;
                    if (bill1CreatedAt > bill2CreatedAt) return -1;

                    return 0;

                }), isPremiumByPro, proInfo });

            }

        } catch (error) {

            console.error('error', error);

        } finally {

            this.props.closeLoader();

        }

    }

    downloadInvoice = async (_id, type) => {

        this.props.openLoader();
        let response;
        let error;
        if (type === 'investment') {

            response = await PaymentModel.getUserInvestmentInvoice(_id).catch(e => error = e);

        } else {

            response = await PaymentModel.getUserPaymentInvoice(_id).catch(e => error = e);

        }

        if (response) {

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Recibo.pdf');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            this.props.closeLoader();

        } else {

            const { openGenericModal, closeGenericModal } = this.props;

            this.props.closeLoader();

            openGenericModal({
                type: 'simple',
                title:{
                    text: I18n.t('messages.alert'),
                    classes: ['heading-2']
                },
                description:{
                    text: I18n.t('messages.modal.commonError'),
                    classes: ['paragraph', 'regular']
                },
                buttons:[
                    {
                        text: I18n.t('actions.accept'),
                        callback: () => {

                            closeGenericModal();

                        },
                        options: {
                            primary: true,
                            color: '#fff',
                            fluid: true,
                            upper: true,
                        }
                    }

                ]
            });

            console.error(error);

        }

    }

    onCancelSuscription = async () => {

        const activeSubscription = _.first(_.filter(this.state.billings, ({ type }) => ['annualPayment', 'monthlyPayment'].includes(type)));
        const { openGenericModal, closeGenericModal } = this.props;

        if (['iOS'].includes(activeSubscription.platform)) {

            const storeMessage = {
                type: 'simple',
                title: {
                    text: I18n.t('messages.toast'),
                    classes: ['heading-2']
                },
                description: {
                    text: I18n.t('messages.appStoreCancelSubscription', { market: activeSubscription.platform === 'Android' ? 'Google Play' : 'Apple Store' }),
                    classes: ['paragraph', 'regular']
                },
                buttons: [{
                    text: I18n.t('actions.close'),
                    callback: () => {

                        closeGenericModal();

                    },
                    options: {
                        primary: true,
                        color: '#fff',
                        fluid: true,
                        upper: true
                    }
                }]
            };
            openGenericModal(storeMessage);

        } else {

            let messageError = {
                type: 'simple',
                title: {
                    text: I18n.t('messages.alert'),
                    classes: ['heading-2']
                },
                description: {
                    text: I18n.t('messages.errorCancelSuscription'),
                    classes: ['paragraph', 'regular']
                },
                buttons: [{
                    text: I18n.t('actions.close'),
                    callback: () => {

                        closeGenericModal();

                    },
                    options: {
                        primary: true,
                        color: '#fff',
                        fluid: true,
                        upper: true,
                        style: {'marginBottom': '10px'}
                    }
                },
                {
                    text: I18n.t('actions.goSupport'),
                    callback: () => {

                        closeGenericModal();

                    },
                    options: {
                        terciary: true,
                        fluid: true,
                        upper: true
                    }
                }]
            };

            let messageSuccces = {
                type: 'simple',
                title: {
                    text: I18n.t('messages.toast'),
                    classes: ['heading-2']
                },
                description: {
                    text: I18n.t('messages.successCancelSuscription'),
                    classes: ['paragraph', 'regular']
                },
                buttons: [{
                    text: I18n.t('actions.close'),
                    callback: () => {

                        closeGenericModal();

                    },
                    options: {
                        primary: true,
                        color: '#fff',
                        fluid: true,
                        upper: true
                    }
                }]
            };

            try {

                this.props.openLoader();
                await PaymentModel.removeSuscription();
                this.updateStatusAfterCancelSuscription();
                this.props.closeLoader();
                openGenericModal(messageSuccces);

            } catch (error) {

                this.props.closeLoader();
                openGenericModal(messageError);

            }

        }

    }

    updateStatusAfterCancelSuscription = async () => this.props.getRole();

    render() {

        const { billings, proInfo } = this.state;
        const { role, userSession: { name, username } } = this.props;
        const roles = _.get(role || {}, 'role', []);
        const isPremium = roles.includes('premium');

        return (
            <div className="p-wrapper">
                <TopBar {...this.props} menuCallback={()=> this.props.onToggle()} showProfile={false} text={I18n.t('profile.suscriptionEscaped')} invert={true}/>
                <div className="inner">
                    <div className="p-flex">
                        <div className="p-aside">
                            <MenuProfile active="suscription" {...this.props} />
                        </div>
                        <div className="p-content">
                            <div className="info-block">
                                <InfoBlock role={ role } premiumExpirationDate={ role.premiumExpirationDate } isPremium={ isPremium } />
                            </div>
                            <div className="info-payment-wrapper">
                                {billings.length > 0 && <div className="payment-data-title">{I18n.t('profile.suscription.paymentList')}</div>}
                                {billings.length > 0 && <Table basic='very' stackable className="table-payment">
                                    <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell >{I18n.t('profile.receiptNumber')}</Table.HeaderCell>
                                        <Table.HeaderCell>{I18n.t('profile.suscription.createdAt')}</Table.HeaderCell>
                                        <Table.HeaderCell>{I18n.t('profile.suscription.description')}</Table.HeaderCell>
                                        <Table.HeaderCell >{I18n.t('profile.suscription.rangeService')}</Table.HeaderCell>
                                        <Table.HeaderCell >{I18n.t('profile.suscription.paymentMethod')}</Table.HeaderCell>
                                        <Table.HeaderCell >{I18n.t('profile.suscription.totalAmount')}</Table.HeaderCell>
                                        <Table.HeaderCell >{I18n.t('profile.invoice')}</Table.HeaderCell>
                                    </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <RowItem billings={ billings } onDownloadInvoice={this.downloadInvoice}/>
                                    </Table.Body>
                                </Table>}
                            </div>
                            <div className="premium-cta-wrapper">
                                {!isPremium && <OButton size="huge" upper type="button" style={{margin: '5px'}} color="#FFF" primary onClick={()=> this.props.history.push(hasOneYearPaymentPromotion() ? '/profile/promotion' : '/profile/plans')}>
                                    <span>{I18n.t('profile.suscription.goPremium')}</span>
                                </OButton>}
                                {role.hasActiveSubscription && <OButton size="huge" upper style={{margin: '5px'}} type="button" color="#FFF" primary onClick={this.onCancelSuscription }>
                                    <span>{I18n.t('actions.cancelSuscription')}</span>
                                </OButton>}
                            </div>
                            {
                                this.state.isPremiumByPro && proInfo &&
                                <div className="info-block welcome-premium">
                                    <h1>{I18n.t('auth.helloPremium', { name: name || username })}</h1>
                                    <h2>{I18n.t('auth.welcomePremium')}</h2>
                                    {
                                        proInfo?.name ? <p>{I18n.t('auth.welcomePremiumDescription', { proName: proInfo.name })}</p>: <p>{I18n.t('auth.welcomePremiumDescriptionNoName')}</p>
                                    }
                                    <div>
                                        <span dangerouslySetInnerHTML={{__html: I18n.t('auth.premiumInclusionsList.premiumListTitle')}}></span>
                                        <ul>
                                            <li>{I18n.t('auth.premiumInclusionsList.premiumListItem1')}</li>
                                            <li>{I18n.t('auth.premiumInclusionsList.premiumListItem2')}</li>
                                            <li>{I18n.t('auth.premiumInclusionsList.premiumListItem3')}</li>
                                            <li>{I18n.t('auth.premiumInclusionsList.premiumListItem4')}</li>
                                            <li>{I18n.t('auth.premiumInclusionsList.premiumListItem5')}</li>
                                            <li>{I18n.t('auth.premiumInclusionsList.premiumListItem6')}</li>
                                        </ul>
                                    </div>     
                                    <div>
                                        <span>{I18n.t('auth.linkRule')}</span>
                                        <p dangerouslySetInnerHTML={{__html: I18n.t('auth.linkRuleDescription')}}></p>
                                        <p>{I18n.t('auth.enjoyPractice')}</p>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <Footer {...this.props} type="branded"/>
            </div>
        );

    }

}

const Wrapped = SideBarProFileHoc(UserSuscription, 'suscription');

export default connect(null, { openGenericModal, closeGenericModal, openLoader, closeLoader, getRole })(Wrapped);